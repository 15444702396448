/**
  * @desc tools - contains mixins
  * use gradients.com to obtain gradients
  */

/** @group Helper Classes */

.ac {
  text-align: center;
}

.ma {
  margin-left: auto;
  margin-right: auto;
}

.site-width {
  max-width: $site-width;
}

// .clearfix,
// .grid {
//   &::before,
//   &::after {
//     content: "";
//     display: table;
//   }
//   &::after {
//     clear: both;
//   }
// }
// Mixin itself
@mixin clearfix() {
  &:before,
  &:after {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
  }
}

.clearfix {
  @include clearfix();
}

.bold-text { 
  font-weight: bold;
}