/**
  * @desc compiles scss files based on ITCSS
  */
/** @group settings */
/**
  * @desc settings - contains fonts, colours
  * use the coolors.co service to obtain colours and their names
  */
/** @group Config */
/** @group tools */
/**
  * @desc tools - contains mixins
  * use gradients.com to obtain gradients
  */
/** @group Helper Classes */
/* line 8, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_tools.scss */
.ac {
  text-align: center; }

/* line 12, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_tools.scss */
.ma {
  margin-left: auto;
  margin-right: auto; }

/* line 17, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_tools.scss */
.site-width {
  max-width: 75em; }

/* line 34, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_tools.scss */
.clearfix:before, .clearfix:after {
  content: " ";
  display: table; }

/* line 39, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_tools.scss */
.clearfix:after {
  clear: both; }

/* line 48, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_tools.scss */
.bold-text {
  font-weight: bold; }

/** @group generic */
/**
  * @desc generic - resets the browser, contains normalised values
  * libraries like normalize.css
  */
/* line 6, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_generic.scss */
*,
*::before,
*::after {
  box-sizing: border-box; }

/* line 12, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_generic.scss */
* {
  margin: 0;
  padding: 0; }

/* line 17, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_generic.scss */
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal; }

/* line 26, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_generic.scss */
ul {
  list-style: none; }

/* line 30, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_generic.scss */
img,
video,
embed,
object {
  max-width: 100%; }

/* line 37, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_generic.scss */
img {
  vertical-align: middle; }

/* line 41, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_generic.scss */
label,
button {
  cursor: pointer; }

/** @group elements */
/**
  * @desc elements - contains default styles for basic text
  * including div's, fieldset's, html, body, etc
  */
/** @group General Typography */
/* line 8, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_elements.scss */
html,
body {
  background: #e3e3e3;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 300;
  font-size: 16px; }

/* line 16, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_elements.scss */
h3 {
  font-size: 200%;
  font-size: 2rem; }

/* line 21, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_elements.scss */
.h3 {
  font-size: 1.52rem;
  line-height: 110%;
  margin: 1.6466666667rem 0 1.168rem 0; }

/* line 27, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_elements.scss */
a[disabled="disabled"] {
  pointer-events: none; }

/** @group objects */
/**
  * @desc objects - contains layouts, modules, which are the containers for components,
  * form module + hover state,
  * module rows, module elements + modifiers
  * classess
  */
/** @group section */
/* line 9, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_objects.scss */
.section__title {
  font-size: 32px;
  line-height: 42px;
  opacity: 0.7;
  font-weight: 500;
  margin: 0px;
  text-align: center;
  padding-bottom: 40px;
  padding-top: 60px; }
  /* line 19, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_objects.scss */
  .section__title .material-icons {
    font-size: 30px;
    position: relative;
    top: 5px;
    left: 10px; }

/* line 27, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_objects.scss */
.section__icon {
  margin-right: 30px; }

/** @group panel */
/* line 32, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_objects.scss */
.panel {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px 20px 20px 10px; }

/* line 39, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_objects.scss */
.project .row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start; }
  /* line 44, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_objects.scss */
  .project .row .col {
    margin-left: 0; }

/** @group components */
/**
  * @desc components - which belong on the User Interface,
  *         - self contontained,
  *         - object Single Responsibility Principle
  *         - solves a specific tasks
  *         - reusable
  *         - recursive - contains other components   
  * addresses business logic
  */
/** @group navigation */
/* line 13, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.nav .nav__main .nav__item .nav__link {
  opacity: 0.8;
  letter-spacing: 0.1rem; }

/* line 18, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.nav .nav__main .nav__item .nav__link:hover {
  opacity: 1;
  background-color: inherit;
  border-bottom: 2px solid white;
  font-weight: 300; }

/* line 25, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.nav .nav__main .nav__item.active {
  background-color: transparent; }

/* line 30, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
nav ul a.active {
  background: rgba(0, 0, 0, 0.2);
  background-color: transparent;
  border-bottom: 2px solid white;
  font-weight: 300;
  opacity: 1 !important; }

/* line 38, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.container {
  /* Extra small devices (phones, less than 768px) */
  /* No media query since this is the default in Bootstrap */
  max-width: 469px;
  /* Small devices (tablets, 768px and up) */
  /* Medium devices (desktops, 992px and up) */
  /* Large devices (large desktops, 1200px and up) */ }
  @media (min-width: 768px) {
    /* line 38, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
    .container {
      max-width: 750px; } }
  @media (min-width: 992px) {
    /* line 38, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
    .container {
      max-width: 970px; } }
  @media (min-width: 1200px) {
    /* line 38, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
    .container {
      max-width: 1170px; } }

/* line 60, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.sidenav-overlay {
  z-index: 99; }

/** @group about section */
/* line 65, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.about {
  padding-top: 140px;
  margin-bottom: 10px;
  text-align: center; }
  /* line 69, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .about .container .intro {
    background: #fff;
    padding: 40px 40px 80px 30px; }
  /* line 73, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .about .profile-pic {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin-top: -100px; }
    /* line 79, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
    .about .profile-pic img {
      height: 100%;
      width: auto;
      vertical-align: middle;
      margin-top: -70px; }
  /* line 86, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .about .intro-content {
    text-align: center;
    margin: 0 auto;
    float: none; }
    /* line 90, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
    .about .intro-content h2 {
      font-weight: bold;
      margin: 0px;
      font-size: 35px;
      line-height: 52px;
      opacity: 0.7; }
    /* line 97, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
    .about .intro-content span {
      font-size: 18px;
      line-height: 34px;
      color: #8a8a8b;
      margin-bottom: 25px; }
    /* line 103, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
    .about .intro-content p {
      font-size: 15px;
      line-height: 27px;
      opacity: 0.7;
      padding: 0 90px;
      margin-bottom: 40px; }

/** @group skills section */
/* line 115, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.skills__icon .material-icons {
  font-size: 30px;
  padding: 10px;
  line-height: 50px;
  text-align: center; }

/** @group progress bar */
/* line 124, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.progress {
  margin-bottom: 30px;
  margin-top: 10px;
  background-color: #185090;
  overflow: visible; }
  /* line 129, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .progress .material-icons {
    font-size: 16px;
    position: absolute;
    margin: -7px -2px 0 0;
    right: 0px; }
  /* line 135, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .progress .progress-bar {
    position: relative; }
  /* line 138, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .progress .progress-bar--skill {
    background-color: white; }

/** @group - experience section */
/* The actual timeline (the vertical ruler) */
/* line 145, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto; }

/* The actual timeline (the vertical ruler) */
/* line 152, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline::after {
  content: '';
  position: absolute;
  width: 6px;
  background-color: white;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -3px; }

/* timeline__container around content */
/* line 164, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline__container {
  padding: 10px 40px;
  position: relative;
  background-color: inherit;
  width: 50%; }

/* The circles on the timeline */
/* line 172, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline__container::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 25px;
  right: -12px;
  background-color: white;
  border: 4px solid #4783c2;
  top: 15px;
  border-radius: 50%;
  z-index: 1; }

/* Place the timeline__container to the left */
/* line 186, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline__left {
  left: 0;
  padding: 20px 40px 10px 0px;
  text-align: right;
  /* Add arrows to the left timeline__container (pointing right) */ }
  /* line 191, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline__left::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 20px;
    width: 0;
    z-index: 1;
    right: 30px;
    border: medium solid white;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent white; }

/* Place the timeline__container to the right */
/* line 206, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline__right {
  left: 50%;
  padding: 20px 0px 10px 40px;
  /* Add arrows to the right timeline__container (pointing left) */
  /* Fix the circle for timeline__containers on the right side */ }
  /* line 210, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline__right::before {
    content: " ";
    height: 0;
    position: absolute;
    top: 20px;
    width: 0;
    z-index: 1;
    left: 30px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent; }
  /* line 223, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline__right::after {
    left: -12px; }

/* The actual content */
/* line 229, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline__content {
  padding: 20px 30px;
  background-color: white;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }

/* line 237, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline__date {
  letter-spacing: 0.05em;
  color: #4783c2;
  font-weight: bold; }

/* line 243, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline__position {
  font-weight: bold;
  font-size: 1.1rem; }

/* line 248, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.timeline__summary {
  line-height: 1.9em; }

/* Media queries - Responsive timeline on screens less than 600px wide */
@media all and (max-width: 600px) {
  /* Place the timelime to the left */
  /* line 255, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline::after {
    left: 31px; }
  /* Full-width timeline__containers */
  /* line 259, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline__container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px; }
  /* Make sure that all arrows are pointing leftwards */
  /* line 265, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline__container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent; }
  /* Make sure all circles are at the same spot */
  /* line 272, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline__left::after,
  .timeline__right::after {
    left: 15px; }
  /* Make all right timeline__containers behave like the left ones */
  /* line 277, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline__right {
    left: 0%; }
  /* line 280, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .timeline__left {
    text-align: left; } }

/** @group - projects section */
/* line 286, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project__list {
  margin: 0;
  padding: 0;
  width: 100%; }

/* line 292, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project__item {
  list-style: none; }

/* line 296, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project__link {
  margin: 10px; }

/* line 300, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project {
  position: relative; }

/* line 304, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project__image {
  display: block;
  width: 100%;
  height: auto; }

/* line 310, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000c10;
  opacity: 0.7;
  overflow: hidden;
  width: 100%;
  height: 0;
  transition: .5s ease; }

/* line 323, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project:hover > .project__overlay {
  height: 100%; }

/* line 327, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project__text {
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: left;
  width: 95%; }
  /* line 336, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .project__text h5 {
    font-size: 20px;
    margin: 1rem 0 0.5rem 0; }
  /* line 341, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .project__text p {
    font-size: 15px; }

/* line 346, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.project__description {
  margin-bottom: 10px; }

/* line 350, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.loader-icon {
  background: url(../images/loading.gif) no-repeat center center;
  background-color: #FFF;
  margin: -22px -22px;
  top: 50%;
  left: 50%;
  z-index: 10000;
  position: fixed;
  width: 44px;
  height: 44px;
  background-size: 30px 30px;
  border-radius: 5px; }

/* line 364, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.screenshots {
  min-height: 440px; }

/* line 368, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
#loader {
  min-height: 530px;
  position: relative;
  display: none; }

/** back button */
/* line 375, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.back-btn {
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  margin: 30px 0;
  display: none; }
  /* line 382, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .back-btn .material-icons {
    position: absolute;
    top: 1px;
    left: 5px; }
  /* line 388, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
  .back-btn .btn {
    padding-left: 30px; }

/** @group about section */
/* line 394, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_components.scss */
.profile-pic img {
  border-radius: 50%; }

/** @group overrides */
/**
  * @desc overrides - contains utilities, modifiers (state and behaviour )
  */
/* line 6, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_overrides.scss */
input:not([type]):focus:not([readonly]) + label,
input[type=text]:not(.browser-default):focus:not([readonly]) + label,
input[type=password]:not(.browser-default):focus:not([readonly]) + label,
input[type=email]:not(.browser-default):focus:not([readonly]) + label,
input[type=url]:not(.browser-default):focus:not([readonly]) + label,
input[type=time]:not(.browser-default):focus:not([readonly]) + label,
input[type=date]:not(.browser-default):focus:not([readonly]) + label,
input[type=datetime]:not(.browser-default):focus:not([readonly]) + label,
input[type=datetime-local]:not(.browser-default):focus:not([readonly]) + label,
input[type=tel]:not(.browser-default):focus:not([readonly]) + label,
input[type=number]:not(.browser-default):focus:not([readonly]) + label,
input[type=search]:not(.browser-default):focus:not([readonly]) + label,
textarea.materialize-textarea:focus:not([readonly]) + label {
  color: #2196F3; }

/* line 22, /Users/neilsymester/Desktop/portfolio/src/stylesheets/_overrides.scss */
input:not([type]), input[type="text"]:not(.browser-default), input[type="password"]:not(.browser-default), input[type="email"]:not(.browser-default), input[type="url"]:not(.browser-default), input[type="time"]:not(.browser-default), input[type="date"]:not(.browser-default), input[type="datetime"]:not(.browser-default), input[type="datetime-local"]:not(.browser-default), input[type="tel"]:not(.browser-default), input[type="number"]:not(.browser-default), input[type="search"]:not(.browser-default), textarea.materialize-textarea {
  border-bottom: 1px solid #2196F3; }
