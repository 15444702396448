/**
  * @desc components - which belong on the User Interface,
  *         - self contontained,
  *         - object Single Responsibility Principle
  *         - solves a specific tasks
  *         - reusable
  *         - recursive - contains other components   
  * addresses business logic
  */

/** @group navigation */
.nav .nav__main .nav__item {
    .nav__link {
        opacity:0.8;
        letter-spacing: 0.1rem;
    }

    & .nav__link:hover {
        opacity: 1;
        background-color: inherit;
        border-bottom: 2px solid white;
        font-weight: 300;
    }

    &.active {
        background-color: transparent;
    }
}

nav ul a.active {
    background: rgba(0,0,0,0.2);
    background-color: transparent;
    border-bottom: 2px solid white;
    font-weight: 300;
    opacity: 1 !important;
}

.container {
    /* Extra small devices (phones, less than 768px) */
    /* No media query since this is the default in Bootstrap */

    max-width: 469px;    

    /* Small devices (tablets, 768px and up) */
    @media (min-width: 768px) { 
        max-width: 750px;    
    }

    /* Medium devices (desktops, 992px and up) */
    @media (min-width: 992px) { 
        max-width: 970px;
    }

    /* Large devices (large desktops, 1200px and up) */
    @media (min-width: 1200px) { 
        max-width: 1170px; 
    }
}

.sidenav-overlay {
    z-index: 99;
}

/** @group about section */
.about {
  padding-top: 140px;
  margin-bottom: 10px;
  text-align: center;
  .container .intro {
    background: #fff;
    padding: 40px 40px 80px 30px;
  }
  .profile-pic {
    width: 100px;
    height: 100px;
    display: inline-block;
    margin-top: -100px;

    img {
      height: 100%;
      width: auto;
      vertical-align: middle;
      margin-top: -70px;
    }
  }
  .intro-content {
    text-align: center;
    margin: 0 auto;
    float: none;
    h2 {
      font-weight: bold;
      margin: 0px;
      font-size: 35px;
      line-height: 52px;
      opacity: 0.7;
    }
    span {
      font-size: 18px;
      line-height: 34px;
      color: #8a8a8b;
      margin-bottom: 25px;
    }
    p {
      font-size: 15px;
      line-height: 27px;
      opacity: 0.7;
      padding: 0 90px;
      margin-bottom: 40px;
    }
  }
}

/** @group skills section */
.skills__icon {
    .material-icons {
        font-size: 30px;
        padding: 10px;
        line-height: 50px;
        text-align: center;
    }
}

/** @group progress bar */
.progress {
    margin-bottom: 30px;
    margin-top: 10px;
    background-color: #185090;
    overflow: visible;
    .material-icons {
        font-size: 16px;
        position: absolute;
        margin: -7px -2px 0 0;
        right: 0px;
    }
    .progress-bar {
        position: relative;
    }
    .progress-bar--skill {
        background-color: white;
    }
}

/** @group - experience section */
/* The actual timeline (the vertical ruler) */
.timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: white;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px;
}

/* timeline__container around content */
.timeline__container {
    padding: 10px 40px;
    position: relative;
    background-color: inherit;
    width: 50%;
}

/* The circles on the timeline */
.timeline__container::after {
    content: '';
    position: absolute;
    width: 25px;
    height: 25px;
    right: -12px;
    background-color: white;
    border: 4px solid $primary-theme;
    top: 15px;
    border-radius: 50%;
    z-index: 1;
}

/* Place the timeline__container to the left */
.timeline__left {
    left: 0;
    padding: 20px 40px 10px 0px;
    text-align: right;
    /* Add arrows to the left timeline__container (pointing right) */
    &::before {
        content: " ";
        height: 0;
        position: absolute;
        top: 20px;
        width: 0;
        z-index: 1;
        right: 30px;
        border: medium solid white;
        border-width: 10px 0 10px 10px;
        border-color: transparent transparent transparent white;
    }
}

/* Place the timeline__container to the right */
.timeline__right {
    left: 50%;
    padding: 20px 0px 10px 40px;
    /* Add arrows to the right timeline__container (pointing left) */
    &::before {
        content: " ";
        height: 0;
        position: absolute;
        top: 20px;
        width: 0;
        z-index: 1;
        left: 30px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }
    /* Fix the circle for timeline__containers on the right side */
    &::after {
        left: -12px;
    }
}

/* The actual content */
.timeline__content {
    padding: 20px 30px;
    background-color: white;
    position: relative;
    border-radius: 2px;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.timeline__date {
    letter-spacing: 0.05em;
    color: #4783c2;
    font-weight: bold;
}

.timeline__position {
    font-weight: bold;
    font-size: 1.1rem;
}

.timeline__summary {
    line-height: 1.9em;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media all and (max-width: 600px) {
    /* Place the timelime to the left */
    .timeline::after {
        left: 31px;
    }
    /* Full-width timeline__containers */
    .timeline__container {
        width: 100%;
        padding-left: 70px;
        padding-right: 25px;
    }
    /* Make sure that all arrows are pointing leftwards */
    .timeline__container::before {
        left: 60px;
        border: medium solid white;
        border-width: 10px 10px 10px 0;
        border-color: transparent white transparent transparent;
    }
    /* Make sure all circles are at the same spot */
    .timeline__left::after,
    .timeline__right::after {
        left: 15px;
    }
    /* Make all right timeline__containers behave like the left ones */
    .timeline__right {
        left: 0%;
    }
    .timeline__left {
        text-align: left;
    }
}

/** @group - projects section */
.project__list {
    margin: 0;
    padding: 0;
    width: 100%;
}

.project__item {
    list-style: none;
}

.project__link {
    margin: 10px;
}

.project {
    position: relative;
}

.project__image {
    display: block;
    width: 100%;
    height: auto;
}

.project__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #000c10;
    opacity: 0.7;
    overflow: hidden;
    width: 100%;
    height: 0;
    transition: .5s ease;
}

.project:hover>.project__overlay {
    height: 100%;
}

.project__text {
    color: white; //font-size: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: left;
    width: 95%;

    h5 {
        font-size:20px;
        margin: 1rem 0 0.5rem 0;
    }

    p {
        font-size: 15px;
    }
}

.project__description {
    margin-bottom:10px;
}

.loader-icon {
	background: url(../images/loading.gif) no-repeat center center;
	background-color: #FFF;
	margin: -22px -22px;
	top: 50%;
	left: 50%;
	z-index: 10000;
	position: fixed;
	width: 44px;
	height: 44px;
	background-size: 30px 30px;
	border-radius: 5px;
}

.screenshots {
	min-height: 440px;
}

#loader {
	min-height: 530px;
	position: relative;
	display: none;
}

/** back button */
.back-btn {
    margin: 0 auto;
    text-align: center;
    padding: 20px 0;
    margin: 30px 0;
	display:none;

    .material-icons{
        position:absolute;
        top:1px;
        left:5px;
    }

    .btn {
        padding-left:30px;
    }
}

/** @group about section */
.profile-pic img{
    border-radius: 50%;
}