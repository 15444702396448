/**
  * @desc generic - resets the browser, contains normalised values
  * libraries like normalize.css
  */
  
*,
*::before,
*::after {
    box-sizing: border-box;
}

* {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

ul {
    list-style: none;
}

img,
video,
embed,
object {
    max-width: 100%;
}

img {
    vertical-align: middle;
}

label,
button {
    cursor: pointer;
}