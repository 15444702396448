/**
  * @desc objects - contains layouts, modules, which are the containers for components,
  * form module + hover state,
  * module rows, module elements + modifiers
  * classess
  */

/** @group section */
.section__title {
  font-size: 32px;
  line-height: 42px;
  opacity: 0.7;
  font-weight: 500;
  margin: 0px;
  text-align: center;
  padding-bottom: 40px;
  padding-top: 60px;

   .material-icons {
	  font-size:30px;
	  position:relative;
	  top: 5px;
	  left:10px;
	}
}

.section__icon {
  margin-right: 30px;
}

/** @group panel */
.panel {
  background-color: white;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  padding: 20px 20px 20px 10px;

}

.project .row {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;

  .col {
    margin-left: 0;
  }
}