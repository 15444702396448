/**
  * @desc compiles scss files based on ITCSS
  */

/** @group settings */
@import "settings";

/** @group tools */
@import "tools";

/** @group generic */
 @import 'generic';

/** @group elements */
@import 'elements';

/** @group objects */
@import 'objects';

/** @group components */
@import 'components';

/** @group overrides */
@import "overrides";