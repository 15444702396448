/**
  * @desc elements - contains default styles for basic text
  * including div's, fieldset's, html, body, etc
  */

/** @group General Typography */

html,
body {
    background: #e3e3e3; //font-size: 16px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif; 
    font-weight: 300;
    font-size: 16px;
}

h3 {
	font-size: 200%;
	font-size: 2rem;
}

.h3 {
	font-size: 1.52rem;
	line-height: 110%;
	margin: 1.6466666667rem 0 1.168rem 0;
}

a[disabled="disabled"] {
  pointer-events: none;
}