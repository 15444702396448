/**
  * @desc settings - contains fonts, colours
  * use the coolors.co service to obtain colours and their names
  */

/** @group Config */

// colors
$color-base: #15263e;
$color-base-light: #374362;
$color-gradient1: #b119ec;
$color-gradient2: #f8027e;
$color-highlight: #a91aef;
// widths
$smallest-width: 20em;
$very-small-width: 30em;
$small-width: 48em;
$medium-width: 60em;
$large-width: 75em;
$very-large-width: 100em;
$site-width: 75em;
//$primary-theme: #03a9f4;
$primary-theme: #4783c2